import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FiAlignJustify } from "react-icons/fi";

const Header = () => {

    const [icon, setIcon] = useState(false);
    const [contactpage, setContactpage] = useState(false);
    const closeMenu = () => {setIcon(false);};

    return (
        <>
            <div className='HeaderContainer' >
                <div className='header-top'>
                    <div className='container design'>
                        <div className='num' style={{ paddingRight: '15px' }}>
                            <span className="fa fa-phone" style={{ paddingRight: '10px' }} />
                            <b>(+91) 98495 62382 | 89199 54071</b>
                        </div>
                        {/* <div className='mail'>
                            <a>
                                <b style={{ color: 'white' }}>javidarchit@gmail.com</b>
                            </a>
                        </div> */}
                        {/* <div className='icons'>
                            <i className="fa fa-instagram" style={{ marginLeft: '20px' }} />
                            <i className="fa fa-youtube-play" style={{ marginLeft: '20px' }} />
                            <i className="fa fa-twitter" style={{ marginLeft: '20px' }} />
                            <i className="fa fa-facebook" style={{ marginLeft: '20px' }} />
                        </div> */}
                    </div>
                </div>


                <header className="site-header header-style-1  nav-wide">
                    <div className="sticky-header main-bar-wraper">
                        <div className="main-bar bg-white">
                            <div className="container header-center">
                                <div className="wt-header-left">
                                    <div className="logo-header">
                                        <div className="logo-header-inner logo-header-one" onClick={closeMenu}>
                                            <Link to="/">
                                                <a >
                                                    <img src="assets/images/RD Interiors logo.png" width={100} height={35} alt />
                                                </a></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="wt-header-center">
                                    <button data-target=".header-nav " data-toggle="collapse" type="button" className="navbar-toggle"  onClick={() => setIcon(!icon)}>
                                        <span className="sr-only">Toggle navigation</span>
                                        {icon ? <span className="fa fa-times" /> : <span className="fa fa-bars" />}

                                    </button>
                                    <div className={`header-nav navbar-collapse collapse ${icon ? 'nav-dark' : ''}`}>
                                        
                                        <ul className=" nav navbar-nav nav-line-animation">
                                        <li className="active" onClick={closeMenu}>
                                                <Link to="/"><a  >Home</a></Link>
                                            </li>
                                            <li onClick={closeMenu}>
                                                <Link to="/AboutUs"><a >About us</a></Link>
                                            </li>
                                            <li onClick={closeMenu}>
                                                <Link to="/sevices-page"><a >Services</a></Link>
                                            </li>
                                            <li onClick={closeMenu}>
                                                <Link to='/grid-images'><a >Gallery</a></Link>
                                            </li>
                                            {/* <li onClick={closeMenu}>
                                                <a >Awards</a>
                                            </li> */}
                                            <li onClick={closeMenu}>
                                                <Link to='/contactus'>  <a>Contact us</a></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wt-header-right ">
                                    <div className="bg-primary wt-header-right-child">
                                        {/* <div className="extra-nav">
                                            <div className="extra-cell">
                                                <a href="#search" className="site-search-btn"><i className="fa fa-search" /></a>
                                            </div>
                                        </div> */}
                                        {/* <div className="extra-nav">
                                            <div className="extra-cell">
                                                <div className="right-arrow-btn">
                                                    <button type="button" className="btn-open contact-slide-show text-white notification-animate" onClick={() => setContactpage(!contactpage)}>
                                                        <i className="fa fa-angle-left" /></button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* {contactpage ?
                                    (<>
                                        <div className="contact-slide-hide">
                                            <div className="contact-nav">
                                                <a href="" className="contact_close" onClick={() => setContactpage(!contactpage)}>
                                                    {contactpage ? <>×</> : <></>}

                                                </a>
                                                <div className="contact-nav-form p-a30 ">
                                                    <form className="cons-contact-form" method="post" action="form-handler.php">
                                                        <div className="m-b30">
                                                            <div className="section-head text-left">
                                                                <h4 className="m-b5">Get In Touch</h4>
                                                            </div>
                                                            <div className="input input-animate">
                                                                <label htmlFor="name">Name</label>
                                                                <input type="text" name="username" id="name" required />
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="input input-animate">
                                                                <label htmlFor="email">Email</label>
                                                                <input type="email" name="email" id="email" required />
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="input input-animate">
                                                                <label htmlFor="message">Textarea</label>
                                                                <textarea name="message" id="message" required defaultValue={""} />
                                                                <span className="spin" />
                                                            </div>
                                                            <div className="text-right">
                                                                <button name="submit" type="submit" value="Submit" className="btn-half site-button m-b15">
                                                                    <span>Submit</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="contact-info text-black m-b30">
                                                        <div className="section-head text-left">
                                                            <h4 className="m-b5">Contact Info</h4>
                                                        </div>
                                                        <div className="wt-icon-box-wraper left p-b40 icon-shake-outer">
                                                            <div className="icon-xs"><i className="flaticon-smartphone  icon-shake" /></div>
                                                            <div className="icon-content">
                                                                <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                                <p>(+91) 89199 54071</p>
                                                                <p>(+91) 98495 62382</p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-icon-box-wraper left p-b40 icon-shake-outer">
                                                            <div className="icon-xs"><i className="flaticon-email  icon-shake" /></div>
                                                            <div className="icon-content">
                                                                <h5 className="m-t0 font-weight-500">Email address</h5>
                                                                <p>demo@gmail.com</p>
                                                            </div>
                                                        </div>
                                                        <div className="wt-icon-box-wraper left icon-shake-outer">
                                                            <div className="icon-xs"><i className="flaticon-placeholder  icon-shake" /></div>
                                                            <div className="icon-content">
                                                                <h5 className="m-t0 font-weight-500">Address info</h5>
                                                                <p>Sr Nagar , Hyderabad.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                    :
                                    (<>
                                    </>)} */}
                                {/* <div id="search">
                                    <span className="close" />
                                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                        <div className="input-group">
                                            <input defaultValue name="q" type="search" placeholder="Type to search" />
                                            <span className="input-group-btn"><button type="button" className="search-btn"><i className="fa fa-search" /></button></span>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>




            </div>





        </>
    )
}

export default Header