import React from 'react'
import { Link } from 'react-router-dom'

const ContactUsPage = () => {
    return (
        <>
            <div>
                <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url(assets/images/banner/4.jpg)' }}>
                    <div className="overlay-main bg-black opacity-07" />
                    <div className="container ImgMar">
                        <div className="wt-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <h2 className="text-white">Contact Us</h2>
                                </div>
                            </div>

                            <div>
                                <ul className="wt-breadcrumb breadcrumb-style-2">
                                    <li><Link to="/"><a>Home</a></Link></li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </div>
                
                <div className="section-full small-device  p-tb80">
                    {/* <div className="container">
                        <div className="gmap-outline m-b80">
                            <div id="gmap_canvas2" className="google-map" />
                        </div>
                    </div> */}
                    <div className="section-content m-b50">
                        <div className="container">

                            <div className="section-head text-center">
                                <div className="wt-separator-outer separator-center">
                                    <div className="wt-separator">
                                        <span className="text-primary text-uppercase sep-line-one ">Trust and recommend</span>
                                    </div>
                                </div>
                                <h2>Contact Us</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="wt-icon-box-wraper center p-lr30 p-tb50  block-shadow">
                                        <div className="icon-md  m-b10"></div>
                                        <div className="icon-content">
                                        <h4>Phone number</h4>
                                        <h5> (+91) 98495 62382</h5>
                                        <h5> (+91) 89199 54071 </h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="wt-icon-box-wraper center p-lr30 p-tb50  block-shadow">
                                        <div className="icon-md  m-b10"><i className="flaticon-email" /></div>
                                        <div className="icon-content">
                                            <h4>Email address</h4>
                                            <h5>idanr715@gmail.com</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="wt-icon-box-wraper center p-lr30 p-tb30  block-shadow">
                                        <div className="icon-md  m-b10"></div>
                                        <div className="icon-content">
                                            <h4>Address</h4>
                                            <h5>#7-1-229/1,</h5>
                                            <h5>Balkampet,</h5>
                                            <h5>Hyderabad. T.S.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default ContactUsPage