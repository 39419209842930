import React from 'react'
import Homebanner from './Homebanner'
import WelcomeSection from './WelcomeSection'
import WhatWedoSection from './WhatWedoSection'
import OurClients from './OurClients'

const Home = () => {
    return (
        <>
        <Homebanner/>
        <WelcomeSection/>
        <WhatWedoSection/>
        {/* <OurClients/> */}
        
        
        
        
        
        </>
    )
}

export default Home