import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";


const WelcomeSectionSlider = () => {
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={60}
            freeMode={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                470: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                1080: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
            }}
        >


                                <div id="sync1" className="owl-carousel owl-theme owl-btn-vertical-center m-b5">
                                            <SwiperSlide>
                                            <div className="item">
                                                <div className="mfp-gallery">
                                                    <div className="wt-box">
                                                        <div className="wt-thum-bx">
                                                            <img src="assets/images/gallery/portrait-2/pic1.jpg" alt />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <div className="item">
                                                <div className="mfp-gallery">
                                                    <div className="wt-box">
                                                        <div className="wt-thum-bx">
                                                            <img src="assets/images/gallery/portrait-2/pic2.jpg" alt />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <div className="item">
                                                <div className="mfp-gallery">
                                                    <div className="wt-box">
                                                        <div className="wt-thum-bx">
                                                            <img src='assets/images/gallery/portrait-2/pic3.jpg' alt />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide><div className="item">
                                                <div className="mfp-gallery">
                                                    <div className="wt-box">
                                                        <div className="wt-thum-bx">
                                                            <img src="assets/images/gallery/portrait-2/pic4.jpg" alt />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></SwiperSlide>
                                            <SwiperSlide><div className="item">
                                                <div className="mfp-gallery">
                                                    <div className="wt-box">
                                                        <div className="wt-thum-bx">
                                                            <img src="assets/images/gallery/portrait-2/pic5.jpg" alt />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></SwiperSlide>
                                </div>
                                    
        </Swiper>
    );
};

export default WelcomeSectionSlider;