import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
const AboutUsSlider = () => {
    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={60}
            freeMode={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                470: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                580: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
                1080: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                },
            }}
        >
        
            <div className="owl-carousel home-carousel-1 owl-btn-bottom-left">
                        <SwiperSlide>
                            <div className="item ">
                                <div className="ow-img " >
                                    <a ><img src="assets/images/welcome-slider/1.jpg" alt /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="ow-img">
                                    <a ><img src="assets/images/welcome-slider/2.jpg" alt  /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="owl-img">
                                    <a ><img src="assets/images/welcome-slider/3.jpg" alt  /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="ow-img">
                                    <a ><img src="assets/images/welcome-slider/4.jpg" alt  /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="ow-img">
                                    <a ><img src="assets/images/welcome-slider/5.jpg" alt  /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>


        </Swiper>
    );
};

export default AboutUsSlider;


