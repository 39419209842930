import React from 'react'
import AboutUsSlider from '../components/slider/AboutUsSlider'
import { Link } from 'react-router-dom'

const AboutUs = () => {
    return (
        <>
            <div>
                <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url(/assets/images/banner/1.jpg)' }}>
                    <div className="overlay-main bg-black opacity-07" />
                    <div className="container ImgMar">
                        <div className="wt-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <h2 className="text-white">About Us</h2>
                                </div>
                            </div>

                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/"><a >Home</a></Link> </li>
                                <li>About </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>


            <div className="section-full p-tb80 bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <div className="welcome-carousel-outer m-b60">
                                    <div className="welcome-bg-block clearfix">
                                        <div className="welcome-carousel-1 prem">
                                        <AboutUsSlider />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">

                                <div className="section-head">
                                    <div className="wt-separator-outer separator-left">
                                        <div className="wt-separator">
                                            <span className="text-primary text-uppercase sep-line-one ">Welcome to R D Interior Decorator</span>
                                        </div>
                                    </div>
                                    <h2>Since we <br /> started work in 1890</h2>
                                </div>

                                <p>R D Interior Decorator is well-known for being Hyderabad's Best Interior Designer and for decorating homes. All of the wants and necessities 
                                    of interior design for your dream home and commercial space are met by our interior designers.</p>
                                <p> You can express your preferences and desires through R D Interior Decorator in order to fit your current way of life. Modern design 
                                    elements like fixed or loose furniture, modular kitchens, and contemporary style improve the aesthetics and usability of your house. 
                                    To meet everyone's needs and to reflect his taste, style, and dignity, R D Interior Decorator offers a broad collection of interior design in Hyderabad.</p>
                                    <p>Experienced designers, architects, and construction specialists make up our team, and they collaborate to ensure that every project is finished to the greatest standards. 
                                        To guarantee that your project is constructed to last and matches your unique demands and preferences, we only utilise the greatest materials and cutting-edge processes.</p>
                                {/* <a className="site-button m-t15 m-b15">Read More</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}

export default AboutUs