import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="site-footer footer-large  footer-light	footer-wide">
                <div className="footer-top overlay-wraper" >
                    <div className="container">
                        <div className="text-center">
                            <div className="footer-link">
                                {/* <ul>
                                    <li><a  data-hover="About">About</a></li>
                                    <li><a data-hover="Gallery">Gallery</a></li>
                                    <li><a  data-hover="Blog">Blog</a></li>
                                    <li><a  data-hover="Portfolio">Portfolio</a></li>
                                    <li><a  data-hover="Contact Us">Contact Us</a></li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="row">
                            {/* ABOUT COMPANY */}

                            {/* TAGS */}
                            <div className="col-lg-6 col-md-4 col-sm-4">
                                <div className="widget text-center widget_address m-b20">
                                    <h4 className="widget-title">Address</h4>
                                    <div className="widget-section">
                                        <ul>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>#7-1-229/1,</b></li>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>Balkampet,</b> </li>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>Hyderabad. T.S.</b></li>
                                        </ul>
                                    </div>
                                    {/* <div className="footer-social-icon">
                                        <ul className="social-icons f-social-link">
                                            <li><a  className="fa fa-google" /></li>
                                            <li><a  className="fa fa-rss" /></li>
                                            <li><a  className="fa fa-facebook" /></li>
                                            <li><a  className="fa fa-twitter" /></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-4">
                                <div className="widget text-center getin-touch">
                                    <h4 className="widget-title">Get In Touch</h4>
                                    <div className="widget-section">
                                        <ul style={{ color: '#50aab2', fontSize: '25px' }}>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>idanr715@gmail.com</b></li>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>(+91) 98495 62382</b></li>
                                            <li><b style={{ fontSize: "20px", color: "#233344" }}>(+91) 89199 54071</b></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* USEFUL LINKS */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-4"> */}
                                {/* <div className="widget text-center">
                                    <h4 className="widget-title">Studio</h4>
                                    <div className="widget-section">
                                        <ul>
                                            <li><a >Terms of Condition</a></li>
                                            <li><a >Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom overlay-wraper">
                    <div className="overlay-main" />
                    <div className="container">
                        <div className="row">
                            <div className="wt-footer-bot-center">
                                <span className="copyrights-text">© 2023 Designed By Dexterous Technology</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer